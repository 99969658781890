import React from 'react'
import { Modal, Button } from 'react-bootstrap'
import {PdfButton,GeneralButton} from './Button'
function ModalDialog() {
  const [isShow, invokeModal] = React.useState(false)
  const initModal = () => {
    return invokeModal(true)
  } 
  const closeModal = () => {
    return invokeModal(false)
  }
  return (
    <>
        <GeneralButton
                        text='Seguro dental'
                        extraClass='home-pdf'
                        onclick={initModal}
                />
      <Modal show={isShow}>
        <Modal.Header closeButton onClick={initModal}>
          <Modal.Title>Seguro dental Americano PP</Modal.Title>
        </Modal.Header>
        <Modal.Body>
        Seguro dental Americano PPO formato para descargar, llénalo y llevátelo en tu próxima cita.
        <br></br>
        <br></br>
        <br></br>
        <PdfButton text={'Descargar'} extraClass="home-pdf"/>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="danger" onClick={closeModal}>
            Cerrar
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  )
}
export default ModalDialog